/**
 * @generated SignedSource<<5bb93a8c61f0df5d3b5242d3afa9590b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type recipientCell_BankTransferFragment$data = {
  readonly bankAccount: {
    readonly accountOwnerName: string;
    readonly bankName: string;
  };
  readonly " $fragmentType": "recipientCell_BankTransferFragment";
};
export type recipientCell_BankTransferFragment$key = {
  readonly " $data"?: recipientCell_BankTransferFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"recipientCell_BankTransferFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "recipientCell_BankTransferFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccount",
      "kind": "LinkedField",
      "name": "bankAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountOwnerName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bankName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OfframpTransfer",
  "abstractKey": null
};

(node as any).hash = "3c58d7c4a63f17deedc46dd05228b0c1";

export default node;
