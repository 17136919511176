import { z } from "zod"

export const addBankAccountSchema = z
  .object({
    accountOwnerName: z.string().min(1, "Full legal name is required."),
    bankName: z.string().min(1, "Bank name is required."),
    accountNumber: z
      .string()
      .min(4, "Your account number should have at least 4 digits")
      .max(17, "Your account number should have no more than 17 digits."),
    routingNumber: z
      .string()
      .min(1, "ACH Routing number is required.")
      .length(9, "Your ACH routing number should have 9 digits."),
    country: z.string().min(1, "Country is required."),
    fullAddress: z.string(),
    addressLine1: z.string(),
    addressLine2: z.string(),
    city: z.string(),
    state: z.string(),
    zip: z.string(),
  })
  // Only do address validation on US addresses.
  // Individual inputs dont need to be validated because it is autopopulated by Google Places API
  .refine(
    (schema) => {
      if (schema.country === "USA" && !(schema.fullAddress.length > 1)) {
        return false
      }
      return true
    },
    {
      path: ["fullAddress"],
      message: "Address is required",
    },
  )

export type AddBankAccountData = z.infer<typeof addBankAccountSchema>
