import type { GraphQLTaggedNode, UseMutationConfig } from "react-relay"
import { useMutation } from "react-relay"
import type { MutationParameters } from "relay-runtime"

export function usePromisifiedMutation<TMutation extends MutationParameters>(
  mutation: GraphQLTaggedNode,
): [(config: UseMutationConfig<TMutation>) => Promise<TMutation["response"]>, boolean] {
  const [commit, isInFlight] = useMutation(mutation)
  return [
    async (args) => {
      return new Promise((resolve, reject) => {
        commit({
          ...args,
          onCompleted: (response, errors) => {
            if (errors) {
              reject(errors)
            } else {
              resolve(response)
            }
          },
          onError: reject,
        })
      })
    },
    isInFlight,
  ]
}
