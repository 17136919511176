import { z } from "zod"

export type VerificationType = "individual" | "business"

export const accountVerificationSchema = z.object({
  name: z.string().min(1, { message: "This field is required." }),
  email: z.string().min(1, { message: "This field is required." }).email({
    message: "Please enter a valid email address.",
  }),
})

export type AccountVerificationSchema = z.infer<typeof accountVerificationSchema>
