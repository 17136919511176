import { validateConfig } from "#validate-config.js"

const parsedConfig = validateConfig(import.meta.env)

const mode = {
  isDev: parsedConfig.MODE === "development",
  isTest: parsedConfig.MODE === "test",
  isProduction: parsedConfig.MODE === "production",
}

const environment = {
  isLocal: parsedConfig.VITE_ENVIRONMENT === "local",
  isStaging: parsedConfig.VITE_ENVIRONMENT === "staging",
  isTest: parsedConfig.VITE_ENVIRONMENT === "test",
  isProduction: parsedConfig.VITE_ENVIRONMENT === "production",
}

export const Config = {
  ...parsedConfig,
  mode: parsedConfig.MODE,
  isDevMode: mode.isDev,
  isTestMode: mode.isTest,
  isProductionMode: mode.isProduction,
  isStagingEnvironment: environment.isStaging,
  isTestEnvironment: environment.isTest,
  isProductionEnvironment: environment.isProduction,
  isDevDeploy: mode.isProduction && environment.isStaging,
  isTestDeploy: mode.isProduction && environment.isTest,
  isProductionDeploy: mode.isProduction && environment.isProduction,
}
