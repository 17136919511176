import { useCallback, useEffect, useMemo, useState } from "react"
import { useMutation } from "react-relay"
import { graphql } from "relay-runtime"
import { v4 as uuidv4 } from "uuid"

import { Dialog, DialogContent, useToast } from "@utopia/ui"

import type { AccountVerificationSchema } from "#utils/account-verification.js"
import { BRIDGE_ORIGIN_URL, getBridgeTOSUrl } from "#utils/bridge.js"

import type {
  useCreateKycStatus_CreateKycStatusMutation,
  useCreateKycStatus_CreateKycStatusMutation$data,
} from "./__generated__/useCreateKycStatus_CreateKycStatusMutation.graphql.js"
import { useToastAndError } from "./use-toast-and-error.js"

const CreateKycStatusMutation = graphql`
  mutation useCreateKycStatus_CreateKycStatusMutation(
    $input: KycStatusInput!
    $workspaceId: CUID2!
  ) {
    kycStatusCreate(input: $input, workspaceId: $workspaceId) {
      id
      workspaceId
      name
      email
      status
      kycUrl
      createdAt
      updatedAt
    }
  }
`

interface UseCreateKycStatusProps {
  workspaceId: string
  onKycStatusCreated: (
    kycStatus: useCreateKycStatus_CreateKycStatusMutation$data["kycStatusCreate"],
  ) => void
}

export const useCreateKycStatus = ({
  onKycStatusCreated,
  workspaceId,
}: UseCreateKycStatusProps) => {
  const { toast } = useToast()
  const toastAndError = useToastAndError()
  const [tosModalOpen, tosModalSetOpen] = useState(false)
  const [commitCreateKycStatus] = useMutation<useCreateKycStatus_CreateKycStatusMutation>(
    CreateKycStatusMutation,
  )
  const [submittedData, setSubmittedData] = useState<
    AccountVerificationSchema | undefined
  >()

  const handleSubmit = useCallback(
    (data: AccountVerificationSchema) => {
      const input = {
        name: data.name,
        email: data.email,
      }

      tosModalSetOpen(true)
      setSubmittedData(input)
    },
    [tosModalSetOpen],
  )

  const createKycStatus = useCallback(async () => {
    if (!submittedData) {
      toastAndError("Failed to create account", "Missing submitted data")
      return
    }

    commitCreateKycStatus({
      variables: {
        workspaceId,
        input: {
          ...submittedData,
          idempotencyKey: uuidv4(),
        },
      },
      onCompleted: (data) => {
        toast({
          title: "Account successfully created.",
        })
        tosModalSetOpen(false)
        onKycStatusCreated(data.kycStatusCreate)
      },
      onError: (error) => {
        toastAndError("Failed to create account", error)
        tosModalSetOpen(false)
      },
    })
  }, [
    commitCreateKycStatus,
    submittedData,
    toast,
    toastAndError,
    tosModalSetOpen,
    onKycStatusCreated,
    workspaceId,
  ])

  // Bridge iframe message listener
  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      if (event.origin !== BRIDGE_ORIGIN_URL) {
        return
      }

      await createKycStatus()
    }

    window.addEventListener("message", handleMessage)

    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [createKycStatus])

  const bridgeTOSURL = submittedData?.email && getBridgeTOSUrl(submittedData.email)

  const tosModal = useMemo(
    () => (
      <Dialog open={tosModalOpen} onOpenChange={tosModalSetOpen}>
        <DialogContent>
          <iframe
            className="h-[334px] w-full"
            src={bridgeTOSURL}
            title="Bridge's Terms of Service"
          />
        </DialogContent>
      </Dialog>
    ),
    [bridgeTOSURL, tosModalOpen, tosModalSetOpen],
  )

  return { tosModal, handleSubmit }
}
