/**
 * @generated SignedSource<<2bf939452410b636cd8e4f16aa886f30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WorkspaceType = "business" | "individual";
import { FragmentRefs } from "relay-runtime";
export type personaVerification_WorkspaceFragment$data = {
  readonly kycStatus: {
    readonly kycUrl: string;
  };
  readonly workspaceType: WorkspaceType;
  readonly " $fragmentType": "personaVerification_WorkspaceFragment";
};
export type personaVerification_WorkspaceFragment$key = {
  readonly " $data"?: personaVerification_WorkspaceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"personaVerification_WorkspaceFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "personaVerification_WorkspaceFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workspaceType",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "KycStatus",
        "kind": "LinkedField",
        "name": "kycStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kycUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "kycStatus"
    }
  ],
  "type": "Workspace",
  "abstractKey": null
};

(node as any).hash = "04a92c7cf454d0e0560e77f670ba3e25";

export default node;
