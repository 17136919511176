export const BRIDGE_ORIGIN_URL = "https://dashboard.bridge.xyz"

export function getBridgeTOSUrl(email: string) {
  return `${BRIDGE_ORIGIN_URL}/accept-terms-of-service?email=${encodeURIComponent(email)}`
}

export const personaVerificationSteps = [
  {
    title: "First, verify your legal entity with Persona.",
    description:
      "You’ll be asked to provide a description of your legal entity, your employer identification number or tax ID, and a few other details.",
  },
  {
    title: "Then, verify your beneficial owners or managers.",
    description:
      "During the first step, you’ll identify beneficial owners or managers, who will then receive an email from Persona to verify their identities.",
  },
]
