/**
 * @generated SignedSource<<8b473279833882716f80d2dc2294e89a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sentAtCell_BankTransferFragment$data = {
  readonly sentAt: string;
  readonly " $fragmentType": "sentAtCell_BankTransferFragment";
};
export type sentAtCell_BankTransferFragment$key = {
  readonly " $data"?: sentAtCell_BankTransferFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"sentAtCell_BankTransferFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sentAtCell_BankTransferFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sentAt",
      "storageKey": null
    }
  ],
  "type": "OfframpTransfer",
  "abstractKey": null
};

(node as any).hash = "37b8347a4a618a2f807e578ba9199893";

export default node;
